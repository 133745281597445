<template>
  <div>
    <b-card
      title="Input Groups with Buttons"
      no-body
      class="mb-0 pt-2 pl-2 pr-2"
    >
      <b-col
        cols="12"
        md="6"
        class="mb-1 mb-md-0"
      >
        <b-form-group
          label="Buscar archivo Xml"
          label-for="archivo xml"
        >
          <b-form-file
            id="fileToUpload"
            v-model="fileToUpload"
            name="fileToUpload"
            accept="text/xml"
            placeholder="Seleccione archivo"
            drop-placeholder="Drop file here..."
            @change="fileUploaded"
          />
        </b-form-group>
      </b-col>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>

            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="CodigoProveedor"
                rules="required"
              >
                <b-form-group
                  label="CodigoProveedor"
                  label-for="codigoProveedor"
                >
                  <b-form-input
                    v-model="formData.codigoProveedor"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Proveedor"
                rules="required"
              >
                <b-form-group
                  label="Proveedor"
                  label-for="nombreProveedor"
                >
                  <b-form-input
                    v-model="formData.nombreProveedor"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Proveedor RUC"
                rules="required"
              >
                <b-form-group
                  label="Proveedor RUC"
                  label-for="rucProveedor"
                >
                  <b-form-input
                    v-model="formData.rucProveedor"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Proveedor Direccion"
                rules="required"
              >
                <b-form-group
                  label="Proveedor Direccion"
                  label-for="direccionProveedor"
                >
                  <b-form-input
                    v-model="formData.direccionProveedor"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Fecha Emision"
                rules="required"
              >
                <b-form-group
                  label="Fecha Emision"
                  label-for="fechaEmision"
                >
                  <b-form-input
                    v-model="formData.fechaEmision"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Condicion Pago"
                rules="required"
              >
                <b-form-group
                  label="Condicion Pago"
                  label-for="condicionPago"
                >
                  <b-form-input
                    v-model="formData.condicionPago"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Fecha Vencimiento"
                rules="required"
              >
                <b-form-group
                  label="Fecha Vencimiento"
                  label-for="fechaVencimiento"
                >
                  <b-form-input
                    v-model="formData.fechaVencimiento"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Codigo Ubigeo Proveedor"
                rules="required"
              >
                <b-form-group
                  label="Codigo Ubigeo Proveedor"
                  label-for="ubigeoProveedor"
                >
                  <b-form-input
                    v-model="formData.ubigeoProveedor"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Nro Cuotas Pago"
                rules="required"
              >
                <b-form-group
                  label="Nro Cuotas Pago"
                  label-for="cuotaPago"
                >
                  <b-form-input
                    v-model="formData.cuotaPago"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Nro Comprobante"
                rules="required"
              >
                <b-form-group
                  label="Nro Comprobante"
                  label-for="nroComprobante"
                >
                  <b-form-input
                    v-model="formData.nroComprobante"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Ruc Cliente"
                rules="required"
              >
                <b-form-group
                  label="Ruc Cliente"
                  label-for="rucCliente"
                >
                  <b-form-input
                    v-model="formData.rucCliente"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Cliente"
                rules="required"
              >
                <b-form-group
                  label="Cliente"
                  label-for="nombreCliente"
                >
                  <b-form-input
                    v-model="formData.nombreCliente"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="5"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Direccion Cliente"
                rules="required"
              >
                <b-form-group
                  label="Direccion Cliente"
                  label-for="direccionCliente"
                >
                  <b-form-input
                    v-model="formData.direccionCliente"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-1 mt-2 mb-md-0"
            >
              <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="items"
                responsive
                :fields="fieldsDetalle"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros"
                :busy="isBusy"
              >
                <!-- Column: precio -->
                <template #cell(productoNombre)="data">
                  <b-form-input
                    v-model="data.item.descriptionItemInvoice"
                    :disabled="true"
                    size="sm"
                  />
                </template>
                <template #cell(inputUnidadMedida)="data">
                  <b-form-input
                    v-model="data.item.invoicedQuantityUnitCode"
                    :disabled="true"
                    size="sm"
                  />
                </template>
                <!-- Column: Cantidad -->
                <template #cell(inputCantidad)="data">
                  <b-form-input
                    v-model="data.item.invoicedQuantity"
                    type="number"
                    size="sm"
                    :disabled="true"
                  />
                </template>
                <!-- Column: precio -->
                <template #cell(inputPrecio)="data">
                  <b-form-input
                    v-model="data.item.PriceAmountInvoice"
                    size="sm"
                    :disabled="true"
                    type="number"
                  />
                </template>
                <!-- Column: precio -->
                <template #cell(inputIgv)="data">
                  <b-form-input
                    v-model="data.item.priceIgv"
                    size="sm"
                    :disabled="true"
                    type="number"
                  />
                </template>
                <!-- Column: Subtotal -->
                <template #cell(inputSubtotal)="data">
                  <b-form-input
                    v-model="data.item.subTotalRowInvoice"
                    readonly
                    size="sm"
                    :disabled="true"
                  />
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            />
            <b-col
              cols="12"
              md="2"
              class="mb-1 mt-1 mb-md-0"
            >
              <b-form-group
                label="Valor de venta"
                label-for="valorVenta"
              >
                <b-form-input
                  v-model="formData.valorVenta"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mt-1 mb-md-0"
            >
              <b-form-group
                label="Sub-Total"
                label-for="Sub-Total"
              >
                <b-form-input
                  v-model="formData.subTotal"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mt-1 mb-md-0"
            >
              <b-form-group
                label="Total IGV"
                label-for="totalIgv"
              >
                <b-form-input
                  v-model="formData.totalIgv"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            />
            <b-col
              cols="12"
              md="2"
              class=" mb-md-0"
            >
              <b-form-group
                label="Total FISE"
                label-for="totalFise"
              >
                <b-form-input
                  v-model="formData.totalFise"
                  type="number"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class=" mb-md-0"
            >
              <b-form-group
                label="Importe Total"
                label-for="importeTotal"
              >
                <b-form-input
                  v-model="formData.importeTotal"
                  type="number"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class=" mb-md-0"
            >
              <b-form-group
                label="Percepcion"
                label-for="percepcion"
              >
                <b-form-input
                  v-model="formData.percepcion"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="10"
            />

            <b-col
              cols="12"
              md="2"
              class=" mb-md-0"
            >
              <b-form-group
                label="Total Cobrar"
                label-for="totalCobrar"
              >
                <b-form-input
                  v-model="formData.totalCobrar"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            class="text-center"
            align-v="center"
          >
            <b-col
              cols="12"
              md="4"
            />
            <b-col
              cols="12"
              md="4"
            >
              <b-button
                variant="primary"
                class="btn-icon"
                type="submit"
                size="lg"
              >
                Guardar
                <feather-icon icon="SaveIcon" />
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="4"
            />
          </b-row>
        </b-form>
      </validation-observer>

    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  BCard, VBTooltip, BCol, BRow, BFormGroup, BForm, BFormInput, BButton, BTable, BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useNotify } from '@/helpers/toast'
// import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BCard,
    // BFormDatepicker,
    BCol,
    BRow,
    BFormGroup,
    BForm,
    BFormInput,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BTable,
    BFormFile,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
    }
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const titleForm = ref('')
    const sortBy = ref(null)
    const isBusy = ref(false)
    const isVentaRelacionado = ref(false)
    const blankData = {
      codigoProveedor: '',
      nombreProveedor: '',
      rucProveedor: '',
      direccionProveedor: '',
      fechaEmision: '',
      condicionPago: '',
      fechaVencimiento: '',
      ubigeoProveedor: '',
      cuotaPago: '',
      nroComprobante: '',
      xml: '',
    }
    const presentaciones = ref([])
    const presentacionSel = ref({})
    const items = ref([])
    const fieldsDetalle = ref([
      { key: 'idDetalle', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'productoNombre', label: 'Producto', sortable: true, thStyle: { width: '35%' } },
      { key: 'inputUnidadMedida', label: 'Unidad M.', thStyle: { width: '14%' } },
      { key: 'inputCantidad', label: 'Cantidad', thStyle: { width: '12%' } },
      { key: 'inputPrecio', label: 'Precio', thStyle: { width: '12%' } },
      { key: 'inputIgv', label: 'Igv', thStyle: { width: '10%' } },
      { key: 'inputSubtotal', label: 'Subtotal', thStyle: { width: '12%' } },
    ])
    const formData = ref(JSON.parse(JSON.stringify(blankData)))
    const fileToUpload = ref(null)
    const resetData = () => {
      formData.value = JSON.parse(JSON.stringify(blankData))
      fileToUpload.value = null
    }

    const selectVendedor = ref([])
    const selectTipoVenta = ref([])
    const selectFormaPago = ref([])
    const selectTipoDocumento = ref([])
    const selectSerie = ref([])
    const selectunidadMedida = ref([])
    const selectUbigeo = ref([])
    const selectConductor = ref([])
    const selectPlaca = ref([])
    const userProfile = ref(localStorage.getItem('userProfile'))
    const userData = ref(JSON.parse(localStorage.getItem('userData')))

    const selectProducto = ref([])
    const selectPercepcionIgv = ref([
      { descripcion: 'Combustibles líquidos derivados del petróleo. ', id: '0.01' },
      { descripcion: 'Bienes Importados de manera definitiva.', id: '0.1' },
      { descripcion: 'Bienes incluidos en el  Apéndice 1 (GAS LICUADO) ', id: '0.2' },
      { descripcion: 'Operación por la que se emita comprobante de pago que permita ejercer el derecho al crédito fiscal y el cliente (adquirente) sea designado Agente de Percepción.', id: '0.05' },

    ])

    const initData = () => {
      formData.value.tipo_de_transporte = { descripcion: 'TRANSPORTE PUBLICO', id: '01' }
      formData.value.motivo_de_traslado = { descripcion: 'TRASLADO EMISOR ITINERANTE CP', id: '18' }
      // formData.value.tipoDocumento = {}
    }

    const formatDateSend = date => {
      console.info('formatDateSend', date)
      let response = ''
      if (date !== null && date !== undefined && typeof (date) !== 'string') {
        response = `${date.day}-${date.month}-${date.year}`
      }
      if (typeof (date) === 'string') {
        const partDate = date.split('-')
        response = `${partDate[2]}/${partDate[1]}/${partDate[0]}`
      }
      return response
    }

    const formParse = () => {
      const form = {
        sunatCompra: {},
        detalleCompras: [],
      }

      const sunatVenta = {
        clienteDato: `${formData.value.nombreCliente}|${formData.value.rucCliente}|${formData.value.direccionCliente}`,
        nombreProveedor: formData.value.nombreProveedor,
        rucProveedor: formData.value.rucProveedor,
        codmoneda: '',
        condicionPago: formData.value.condicionPago,
        fechaEmision: formData.value.fechaEmision, // '10-08-2023', // formatDateSend(formData.value.fechaEmision),
        fechaVencimiento: formData.value.fechaVencimiento, // '10-08-2023', // formatDateSend(formData.value.fechaVencimiento),
        importeTotal: formData.value.importeTotal,
        montoIgv: formData.value.totalIgv,
        nroComprobante: formData.value.nroComprobante,
        nroCuotaPago: formData.value.cuotaPago,
        nroGuia: '',
        observacion: '',
        percepcion: formData.value.percepcion,
        proveedorDato: `${formData.value.codigoProveedor}|${formData.value.nombreProveedor}|${formData.value.rucProveedor}|${formData.value.direccionProveedor}|${formData.value.ubigeoProveedor} `,
        subTotal: formData.value.subTotal,
        total: formData.value.importeTotal,
        totalCobrar: formData.value.totalCobrar,
        totalFise: formData.value.totalFise,
        valorVenta: formData.value.valorVenta,
        xml: formData.value.xml,
      }
      form.sunatCompra = sunatVenta
      items.value.forEach(element => {
        const row = {
          cantidad: element.invoicedQuantity,
          igv: element.priceIgv,
          precioCompra: element.PriceAmountInvoice,
          precioUnitario: element.PriceAmountInvoice,
          precioVenta: element.PriceAmountInvoice,
          productoDato: element.descriptionItemInvoice,
          subTotal: element.subTotalRowInvoice,
          subtotalIgv: parseFloat(element.invoicedQuantity * element.priceIgv).toFixed(2),
          sunatCompra: {
            compraId: 0,
          },
          unidadMedida: element.invoicedQuantityUnitCode,
          utilidad: 0,
        }
        form.detalleCompras.push(row)
      })
      // console.log(JSON.stringify(form))
      return (form)
    }
    const onSubmit = async () => {
      try {
        Vue.swal({
          title: 'Registrando la Compra',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: async () => {
            Vue.swal.showLoading()
            const service = 'comercial/COMPRA_CREATE'
            // formData.value.presentacion = presentacionSel.value
            await store.dispatch(service, formParse())
              .then(response => {
                Vue.swal.close()
                notify('Respuesta', response.message, 'success')
                items.value = []
                resetData()
              })
              .catch(error => {
                Vue.swal.close()
                notify('Error', error.message, 'danger')
                // throw new Error(error)
              })
          },
        })
      } catch (error) {
        console.log(error)
        Vue.swal.close()
        notify('Error', error.message, 'danger')
      }
    }

    const addDetalleLibre = () => {
      const row = {
        productoNombre: '',
        precio: parseFloat(0).toFixed(2),
        cantidad: parseFloat(0).toFixed(2),
        subtotal: parseFloat(0).toFixed(2),
        key: (items.value.length) === 0 ? 0 : (items.value.length + 1),
        unidadMedida: { descripcion: 'UNIDAD', abreviatura: 'UND', convercion: 1 },
        isService: true }
      items.value.push(row)
    }
    const fileUploaded = event => {
      Vue.swal({
        title: 'Extrayendo Informacion',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          selectUbigeo.value = []
          Vue.swal.showLoading()
          resetData()
          const file = event.target.files[0]
          console.log(file)
          const file1 = document.getElementById('fileToUpload').files[0]
          const reader = new FileReader()
          reader.readAsText(file1)
          reader.onloadend = () => {
            const parser = new DOMParser()
            const doc = parser.parseFromString(reader.result, 'application/xml')
            formData.value.xml = doc.getElementsByTagName('Invoice')[0].innerHTML
            const nroComprobante = doc.getElementsByTagName('cbc:ID')[0].innerHTML
            // console.log({ nroComprobante })
            formData.value.nroComprobante = nroComprobante
            const fechaEmision = doc.querySelectorAll('IssueDate')[0].innerHTML
            // console.log({ fechaEmision })
            formData.value.fechaEmision = fechaEmision
            // PROVEEDOR INICIO
            const accountingSupplierParty = doc.querySelectorAll('AccountingSupplierParty')[0]
            const partyProveedor = accountingSupplierParty.querySelectorAll('Party')[0]
            const partyIdentification = partyProveedor.querySelectorAll('PartyIdentification')[0]
            const rucProveedor = partyIdentification.querySelectorAll('ID')[0].innerHTML
            // console.log({ rucProveedor })
            formData.value.rucProveedor = rucProveedor
            // const partyName = partyProveedor.querySelectorAll('PartyName')[0]
            // const nombreProveedorCorto = ((partyName.querySelectorAll('Name')[0].innerHTML).replace('<![CDATA[', '')).replace(']]>', '')
            // console.log({ nombreProveedorCorto })
            const partyLegalEntity = partyProveedor.querySelectorAll('PartyLegalEntity')[0]
            const nombreProveedor = ((partyLegalEntity.querySelectorAll('RegistrationName')[0].innerHTML).replace('<![CDATA[', '')).replace(']]>', '')
            // console.log({ nombreProveedor })
            formData.value.nombreProveedor = (nombreProveedor)
            const registrationAddres = partyLegalEntity.querySelectorAll('RegistrationAddress')[0]
            // console.info('acaa', registrationAddres.querySelectorAll('ID')[0])
            const ubigeoProveedor = registrationAddres.querySelectorAll('ID')[0] === undefined ? '0000' : registrationAddres.querySelectorAll('ID')[0].innerHTML
            // console.log({ ubigeoProveedor })
            formData.value.ubigeoProveedor = ubigeoProveedor
            const codigoAnexoProveedor = registrationAddres.querySelectorAll('AddressTypeCode')[0].innerHTML
            // console.log({ codigoAnexoProveedor })
            formData.value.codigoProveedor = codigoAnexoProveedor
            const addressLine = registrationAddres.querySelectorAll('AddressLine')[0]
            const direccionProveedor = ((addressLine.querySelectorAll('Line')[0].innerHTML).replace('<![CDATA[', '')).replace(']]>', '')
            // console.log({ direccionProveedor })
            formData.value.direccionProveedor = direccionProveedor
            // const country = registrationAddres.querySelectorAll('Country')[0]
            // const paisProveedor = country.querySelectorAll('IdentificationCode')[0].innerHTML
            // console.log({ paisProveedor })
            // CLIENTE
            const accountingCustomerParty = doc.querySelectorAll('AccountingCustomerParty')[0]
            const customerParty = accountingCustomerParty.querySelectorAll('Party')[0]
            const customerPartyIdentification = customerParty.querySelectorAll('PartyIdentification')[0]
            const rucCliente = customerPartyIdentification.querySelectorAll('ID')[0].innerHTML
            // console.log({ rucCliente })
            formData.value.rucCliente = rucCliente
            const customerPartyLegalEntity = customerParty.querySelectorAll('PartyLegalEntity')[0]
            const nombreCliente = ((customerPartyLegalEntity.querySelectorAll('RegistrationName')[0].innerHTML).replace('<![CDATA[', '')).replace(']]>', '')
            // console.log({ nombreCliente })
            formData.value.nombreCliente = nombreCliente
            const registrationAddressCliente = customerPartyLegalEntity.querySelectorAll('RegistrationAddress')[0]
            const addressLineCliente = registrationAddressCliente.querySelectorAll('AddressLine')[0]
            const direccionCliente = ((addressLineCliente.querySelectorAll('Line')[0].innerHTML).replace('<![CDATA[', '')).replace(']]>', '')
            // console.log({ direccionCliente })
            formData.value.direccionCliente = direccionCliente
            // TERMINOS DE PAGO
            const paymentTerms = doc.querySelectorAll('PaymentTerms')[0]
            const paymentTerms2 = doc.querySelectorAll('PaymentTerms')[1]
            // const terminoPago = paymentTerms.querySelectorAll('ID')[0].innerHTML
            // console.log({ terminoPago })
            const nombreTerminoPago = paymentTerms.querySelectorAll('PaymentMeansID')[0].innerHTML
            // console.log({ nombreTerminoPago })
            formData.value.condicionPago = nombreTerminoPago
            // const montoTerminoPago = paymentTerms.querySelectorAll('Amount')[0].innerHTML
            // console.log({ montoTerminoPago })
            const fechaTerminoPago = paymentTerms2.querySelectorAll('PaymentDueDate')[0].innerHTML
            // console.log({ fechaTerminoPago })
            formData.value.fechaVencimiento = fechaTerminoPago
            const cuotasTerminoPago = paymentTerms2.querySelectorAll('PaymentMeansID')[0].innerHTML
            // console.log({ cuotasTerminoPago })
            formData.value.cuotaPago = cuotasTerminoPago
            // TOTAL IMPUESTO IGV
            const taxTotal = doc.querySelectorAll('TaxTotal')[0]
            const taxAmount = taxTotal.querySelectorAll('TaxAmount')[0]
            const taxMonto = taxAmount.innerHTML
            // const taxtMoneda = taxAmount.getAttribute('currencyID')
            // console.info({ taxMonto }, { taxtMoneda })
            const taxSubtotals = taxTotal.querySelectorAll('TaxSubtotal')
            const listTaxAmount = []
            taxSubtotals.forEach(rowTaxTotal => {
              // console.log(rowTaxTotal)
              const taxableAmount = rowTaxTotal.querySelectorAll('TaxableAmount')[0].innerHTML
              const taxAmountROw = rowTaxTotal.querySelectorAll('TaxAmount')[0].innerHTML
              const taxCategory = rowTaxTotal.querySelectorAll('TaxCategory')[0]
              const taxCategoryCode = taxCategory.querySelectorAll('ID')[0].innerHTML
              const taxScheme = rowTaxTotal.querySelectorAll('TaxScheme')[0]
              const taxSchemeId = taxScheme.querySelectorAll('ID')[0].innerHTML
              const taxSchemeName = taxScheme.querySelectorAll('Name')[0].innerHTML
              const taxSchemeTyprCode = taxScheme.querySelectorAll('TaxTypeCode')[0].innerHTML
              // console.info({ taxableAmount }, { taxAmountROw }, { taxCategoryCode }, { taxSchemeId }, { taxSchemeName }, { taxSchemeTyprCode })
              listTaxAmount.push({ taxableAmount, taxAmountROw, taxCategoryCode, taxSchemeId, taxSchemeName, taxSchemeTyprCode })
            })
            // console.log({ listTaxAmount })

            // LegalMonetaryTotal
            const legalMonetaryTotal = doc.querySelectorAll('LegalMonetaryTotal')[0]
            const lineExtensionAmount = legalMonetaryTotal.querySelectorAll('LineExtensionAmount')[0].innerHTML
            // const taxInclusiveAmount = legalMonetaryTotal.querySelectorAll('TaxInclusiveAmount')[0].innerHTML
            // const allowanceTotalAmount = legalMonetaryTotal.querySelectorAll('AllowanceTotalAmount')[0].innerHTML
            const chargeTotalAmount = legalMonetaryTotal.querySelectorAll('ChargeTotalAmount')[0].innerHTML
            // const payableRoundingAmount = legalMonetaryTotal.querySelectorAll('PayableRoundingAmount')[0].innerHTML
            const payableAmount = legalMonetaryTotal.querySelectorAll('PayableAmount')[0].innerHTML
            // console.info({ lineExtensionAmount }, { taxInclusiveAmount }, { allowanceTotalAmount }, { chargeTotalAmount }, { payableRoundingAmount }, { payableAmount })

            formData.value.valorVenta = lineExtensionAmount
            formData.value.subTotal = lineExtensionAmount
            formData.value.totalIgv = taxMonto
            formData.value.totalFise = chargeTotalAmount
            formData.value.importeTotal = payableAmount

            // InvoiceLine
            const invoiceLine = doc.querySelectorAll('InvoiceLine')
            const listInvoiceLine = []
            invoiceLine.forEach(rowInvoiceLine => {
              // console.log(rowTaxTotal)
              const invoiceLineIndex = rowInvoiceLine.querySelectorAll('ID')[0].innerHTML
              const invoicedQuantity = rowInvoiceLine.querySelectorAll('InvoicedQuantity')[0].innerHTML
              const invoicedQuantityUnitCode = rowInvoiceLine.querySelectorAll('InvoicedQuantity')[0].getAttribute('unitCode')
              const lineExtensionAmountInvoice = rowInvoiceLine.querySelectorAll('LineExtensionAmount')[0].innerHTML
              const pricingReference = rowInvoiceLine.querySelectorAll('PricingReference')[0]
              const alternativeConditionPrice = pricingReference.querySelectorAll('AlternativeConditionPrice')[0]
              const priceAmount = alternativeConditionPrice.querySelectorAll('PriceAmount')[0].innerHTML
              const priceTypeCode = alternativeConditionPrice.querySelectorAll('PriceTypeCode')[0].innerHTML

              const taxTotalInvoice = rowInvoiceLine.querySelectorAll('TaxTotal')[0]
              const taxAmountInvoice = taxTotalInvoice.querySelectorAll('TaxAmount')[0].innerHTML
              const taxSubtotalInvoice = taxTotalInvoice.querySelectorAll('TaxSubtotal')[0]
              const taxableAmountInvoice = taxSubtotalInvoice.querySelectorAll('TaxableAmount')[0].innerHTML
              const taxSubtotalAmountInvoice = taxSubtotalInvoice.querySelectorAll('TaxAmount')[0].innerHTML

              const taxCategoryInvoice = taxSubtotalInvoice.querySelectorAll('TaxCategory')[0]
              const taxCategoryCodeInvoice = taxCategoryInvoice.querySelectorAll('ID')[0].innerHTML
              const taxCategoryPercentInvoice = taxCategoryInvoice.querySelectorAll('Percent')[0].innerHTML
              const taxExemptionReasonCodeInvoice = taxCategoryInvoice.querySelectorAll('TaxExemptionReasonCode')[0].innerHTML
              const taxExemptionReasonlistNameInvoice = taxCategoryInvoice.querySelectorAll('TaxExemptionReasonCode')[0].getAttribute('listName')
              const taxSchemeInvoice = taxCategoryInvoice.querySelectorAll('TaxScheme')[0]
              const taxSchemeCodigoInvoice = taxSchemeInvoice.querySelectorAll('ID')[0].innerHTML
              const taxSchemeNameInvoice = taxSchemeInvoice.querySelectorAll('Name')[0].innerHTML
              const taxSchemeTaxTypeCodeInvoice = taxSchemeInvoice.querySelectorAll('TaxTypeCode')[0].innerHTML

              const itemInvoice = rowInvoiceLine.querySelectorAll('Item')[0]
              const descriptionItemInvoice = ((itemInvoice.querySelectorAll('Description')[0].innerHTML).replace('<![CDATA[', '')).replace(']]>', '')
              // SellersItemIdentification
              const commodityClassification = itemInvoice.querySelectorAll('CommodityClassification')[0]
              const itemClassificationCode = commodityClassification.querySelectorAll('ItemClassificationCode')[0].innerHTML

              const priceInvoice = rowInvoiceLine.querySelectorAll('Price')[0]
              const PriceAmountInvoice = priceInvoice.querySelectorAll('PriceAmount')[0].innerHTML
              listInvoiceLine.push({ invoiceLineIndex,
                invoicedQuantity,
                invoicedQuantityUnitCode,
                lineExtensionAmountInvoice,
                priceAmount,
                priceTypeCode,
                taxAmountInvoice,
                taxableAmountInvoice,
                taxSubtotalAmountInvoice,
                taxCategoryCodeInvoice,
                taxCategoryPercentInvoice,
                taxExemptionReasonCodeInvoice,
                taxExemptionReasonlistNameInvoice,
                taxSchemeCodigoInvoice,
                taxSchemeNameInvoice,
                taxSchemeTaxTypeCodeInvoice,
                descriptionItemInvoice,
                itemClassificationCode,
                PriceAmountInvoice,
                priceIgv: parseFloat(Number(priceAmount) - Number(PriceAmountInvoice)).toFixed(4),
                subTotalRowInvoice: parseFloat(Number(taxSubtotalAmountInvoice) + Number(taxableAmountInvoice)).toFixed(4) })
            })
            items.value = listInvoiceLine
            const additionalProperty = doc.querySelectorAll('AdditionalProperty')

            formData.value.percepcion = Number(payableAmount) * 0.02
            formData.value.totalCobrar = Number(payableAmount) + Number(payableAmount) * 0.02
            additionalProperty.forEach(rowAdditionalProperty => {
              const codigoAdditional = rowAdditionalProperty.querySelectorAll('ID')[0].innerHTML
              const valueAdditional = rowAdditionalProperty.querySelectorAll('Value')[0].innerHTML
              if (codigoAdditional === '0062') {
                formData.value.percepcion = Number(valueAdditional)
                formData.value.totalCobrar = Number(valueAdditional) + Number(payableAmount)
              }
            })
            const datoAdicional = doc.querySelectorAll('DatoAdicional')
            datoAdicional.forEach(rowDatoAdicional => {
              const codigoAdditional = rowDatoAdicional.querySelectorAll('CODIGO')[0].innerHTML
              const valueAdditional = rowDatoAdicional.querySelectorAll('VALOR')[0].innerHTML
              if (codigoAdditional === '101') {
                formData.value.percepcion = Number(valueAdditional)
                formData.value.totalCobrar = Number(valueAdditional) + Number(payableAmount)
              }
            })
          }
          Vue.swal.close()
        },
      })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)
    initData()
    return {
      formData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      titleForm,
      presentaciones,
      presentacionSel,
      selectVendedor,
      selectTipoVenta,
      selectFormaPago,
      selectTipoDocumento,
      selectSerie,
      selectProducto,
      selectunidadMedida,
      fieldsDetalle,
      items,
      sortBy,
      isBusy,
      addDetalleLibre,
      formParse,
      userProfile,
      userData,
      isVentaRelacionado,
      selectUbigeo,
      selectConductor,
      selectPlaca,
      formatDateSend,
      fileUploaded,
      fileToUpload,
      selectPercepcionIgv,
    }
  },
}
</script>

    <style>

    </style>
